<template>
    <Layout>
        <!-- <PageHeader :title="title"/> -->
        <div class="d-flex justify-content-between align-items-center">
           <SettingsTabs  />
            <!-- <button
                :class="`btn btn-primary ${loading ? 'disabled' : ''}`"
                @click="goToForm()">
                {{ $t('create template') }}
            </button> -->
        </div>
        <div class="card data">
            <div class="card-body">
                <div ref="tableScroller" class="table-responsive">
                    <LoadingTable
                        :cols="5"
                        :rows="50"
                        :td_small_class="`d-none`"
                        v-if="loading" />
                    <table
                        v-else
                        class="table table-bordered table-hover table-striped table-nowrap mb-0 table-list">
                        <colgroup>
                           <col width="15%">
                           <col width="85%">
                        </colgroup>
                        <tbody>
                            <tr v-for="(r, i) in data.list" :key="r._id">
                                <td class="text-center">
                                    <div class="template-label">
                                        <input class="form-control" type="text" :value="(`${$t('auto message')} ${i+1}`)" readonly>
                                        <div>{{$t('subject')}}</div>
                                    </div>
                                </td>
                                <td class="text-center" style="width: 10%">
                                    <div class="template-cell">
                                        <input class="form-control" type="text" v-model="r.subject">
                                         <div class="button-items">
                                            <button
                                                class="btn btn-outline-info btn-sm"
                                                @click="update(r)">
                                                {{ $t('edit') }}
                                            </button>
                                            <!-- <button
                                                class="btn btn-outline-danger btn-sm"
                                                @click="remove(r)">
                                                {{ $t('delete') }}
                                            </button> -->
                                        </div>
                                        <textarea class="form-control"  style="resize: none"  rows="5" v-model="r.content"></textarea>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div
                        class="no-data-placeholder"
                        v-if="!data.list.length && !loading">
                        {{ $t('no data found') }}
                    </div>
                </div>
                <Pagination :data="data" @emitPage="initList" />
            </div>
        </div>
        <b-modal
            v-model="points_modal"
            @hidden="reset()"
            centered
            :title="$t(`add points`)"
            title-class="font-18"
            hide-footer>
            <b-form @submit.prevent="create">
                <table class="table table-bordered">
                    <tbody>
                        <tr>
                            <th>{{ $t('action') }}</th>
                            <td>{{ $t('action') }}</td>
                        </tr>
                    </tbody>
                </table>
                <button class="btn btn-warning w-100">
                    {{ $t('submit') }}
                </button>
            </b-form>
        </b-modal>
    </Layout>
</template>

<script>
import Datepicker from 'vue3-datepicker';
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import LoadingTable from '@/components/loading-table';
import Pagination from '../../../components/pagination.vue';
import Multiselect from '@vueform/multiselect';
import Switches from 'vue-switches';
import Swal from 'sweetalert2';
import SettingsTabs from '@/components/settings-tabs';
import { mapActions, mapGetters } from 'vuex';
import random from '../../../mixins/random';
import datetime from '../../../mixins/datetime';
import number from '../../../mixins/number';
import ui from '../../../mixins/ui';
import convert from '../../../mixins/convert';
export default {
    components: {
        Layout,
        PageHeader,
        Pagination,
        Switches,
        LoadingTable,
        Multiselect,
        Datepicker,
        SettingsTabs
    },
    mixins: [datetime, number, random, ui,convert],
    data() {
        return {
            test: null,
            title: `players`,
            filters: {
                keyword: '',
                status: '',
                from: new Date(this.firstDateOftheMonth()),
                to: new Date(),
            },
            data: {
                list: [],
                links: [
                    {
                        label: 'Previous',
                        active: false,
                    },
                    {
                        label: '1',
                        active: true,
                    },
                    {
                        label: 'Next',
                        active: false,
                    },
                ],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            points_modal: false,
            loading: false,
        };
    },
    watch: {
        filters: {
            handler() {
                var queryObj = new Object();
                if (this.filters['status'])
                    queryObj['status'] = this.filters['status'];
                queryObj['unread'] = this.filters['unread'];
                queryObj['from'] = this.dateYmD(this.filters['from']);
                queryObj['to'] = this.dateYmD(this.filters['to']);
                this.$router.replace({ query: queryObj });
                this.initList(1);
            },
            deep: true,
        },
        '$store.state.key'() {
            this.initList(1);
        },
    },
    methods: {
        ...mapActions('ticket', {
            templateGetList: 'templateGetList',
            templateUpdate: 'templateUpdate',
            templateDelete: 'templateDelete',
        }),

        async initList(p) {
            this.resetScroll();
            this.loading = true;
            this.data.list = await this.templateGetList({});
            this.data.total = this.data.list.length;
            this.loading = false;
        },
        async update(r) {
            console.log(r)

            var fd = new FormData();

            fd.append('subject', r.subject);
            fd.append('content', r.content);

            const res = await this.templateUpdate({
                form_data: this.formDataToUrlEncoded(fd),
                id: r._id,
            });

            Swal.fire({
                icon: res.success ? 'success' : 'error',
                title: this.$t(res.data.message),
                showConfirmButton: false,
                timer: 2000,
            });
            if (res.success) {
                this.initList(1);
            }
        },
        async remove(r) {
            if (!this.isAuthorized()) return;
            const confirmed = await Swal.fire({
                title: `${this.$t('are you sure')}?`,
                html: `${this.$t('delete this auto message')}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f1b44c',
                cancelButtonColor: '#74788d',
                cancelButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('confirm'),
            }).then((result) => {
                return result.value;
            });

            if (confirmed) {
                const res = await this.templateDelete({ id: r._id });

                if (res) {
                    Swal.fire({
                        icon: res.success ? 'success' : 'error',
                        title: res.success
                            ? this.$t('template has been deleted')
                            : this.$t('deleting failed'),
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    if (res.success) {
                        this.initList(1);
                    }
                }
            }
        },

        resetFilter() {
            this.filters.keyword = '';
            this.initList(1);
        },
        goToForm(id) {
            var w = window.open(
                `/template/form${id ? '?id=' + id : ''}`,
                '/template/form',
                'width=' + 1020 + ',height=' + (screen.height - 200)
            );
        },
    },
    mounted() {
        this.initList(1);
        window.addEventListener('message', (event) => {
            if (event.origin !== window.origin) {
                return;
            }
            if (event.data == 'reload-templates') {
                this.initList(this.data.current_page);
            }
        });
    },
};
</script>
